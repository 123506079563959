<template>
  <div style="display: flex; flex-direction: column; height: calc(100vh - 180px)">
    <div style="display: flex; flex-wrap: wrap">
      <div class="condition-item">
        <label class="fn-14">任务名称</label>
        <el-input
          style="width: 140px"
          placeholder="请输入任务名称"
          autocomplete="off"
          size="small"
          clearable
          v-model="taskName"
        ></el-input>
      </div>
      <div class="condition-item">
        <label class="fn-14">教育机构</label>
        <el-input
          style="width: 160px"
          placeholder="请输入教育机构"
          autocomplete="off"
          size="small"
          clearable
          v-model="searchTitle"
        ></el-input>
      </div>
      <div class="condition-item">
        <label class="fn-14">摄影师</label>
        <el-input
          style="width: 130px"
          placeholder="请输入摄影师"
          autocomplete="off"
          size="small"
          clearable
          v-model="searchPhotographer"
        ></el-input>
      </div>
      <div class="condition-item">
        <label class="fn-14">采集类型</label>
        <el-select
          size="small"
          clearable
          v-model="searchCollectType"
          placeholder="全部"
          style="width: 120px"
        >
          <el-option
            v-for="item in CollectTypeList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>

      <div class="condition-item">
        <label class="fn-14">联系人</label>
        <el-input
          style="width: 140px"
          placeholder="请输入联系人"
          autocomplete="off"
          size="small"
          clearable
          v-model="searchContact"
        ></el-input>
      </div>
      <div class="condition-item">
        <label class="fn-14">票据号</label>
        <el-input
          style="width: 140px"
          placeholder="请输入票据号"
          autocomplete="off"
          size="small"
          clearable
          v-model="searchBillNo"
        ></el-input>
      </div>

      <div class="condition-item">
        <el-button
          type="primary"
          icon="el-icon-search"
          @click="handleSearch"
          size="small"
          v-if="searchButton"
        >
          查询
        </el-button>
        <el-button
          v-if="downloadButton"
          type="success"
          icon="el-icon-search"
          @click="handleDownload"
          :loading="DownloadLoading"
          size="small"
        >
          导出
        </el-button>
      </div>
    </div>
    <div style="margin-bottom: 15px; flex: 1; overflow: auto">
      <el-table
        :data="ticketList"
        v-loading="ticketLoading"
        stripe
        border
        height="100%"
        :header-cell-style="{ background: '#f5f7fa', color: '#606266' }"
        @sort-change="sortChange"
      >
        <template #empty>
          <p>{{ ticketLoading == true ? "数据加载中" : "暂无数据" }}</p>
        </template>

        <el-table-column prop="title" label="任务名称" width="280" fixed>
        </el-table-column>
        <el-table-column prop="schoolName" label="教育机构" width="160" align="center">
        </el-table-column>
        <el-table-column prop="schoolCode" label="院校代码" width="120" align="center">
        </el-table-column>
        <el-table-column prop="contact" label="联系人" width="150" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.contact }}</span></template
          >
        </el-table-column>
        <el-table-column prop="contactPhone" label="联系电话" width="150" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.contactPhone }}</span></template
          >
        </el-table-column>
        <el-table-column prop="totalCount" label="采集人数" width="120" align="center">
        </el-table-column>
        <el-table-column prop="shotPrice" label="收费标准" width="120" align="center">
          <template slot-scope="scope">
            <span>{{ Number(scope.row.shotPrice).toFixed(2) }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="accountsPayable" label="应交款" width="120" align="center">
          <template slot-scope="scope">
            <span>{{ Number(scope.row.accountsPayable).toFixed(2) }}</span>
          </template>
        </el-table-column>

        <el-table-column prop="photographer" label="摄影师" width="120" align="center">
        </el-table-column>
        <el-table-column
          prop="endDate"
          :formatter="columnDateFormat"
          label="采集时间"
          width="180"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="batchTime"
          :formatter="columnDateFormat"
          label="上传时间"
          width="120"
          align="center"
          sortable="custom"
        >
        </el-table-column>

        <el-table-column
          prop="collectionTime"
          :formatter="columnDateFormat"
          label="交款时间"
          width="120"
          align="center"
          sortable="custom"
        >
          <template slot-scope="scope">
            <span v-if="!scope.row.collectionTime">未收款</span>
            <span v-else>{{ formDateFormat(scope.row.collectionTime) }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="billTime"
          :formatter="columnDateFormat"
          label="财务入账时间"
          width="150"
          align="center"
        >
          <template slot-scope="scope">
            <span v-if="!scope.row.billTime">暂无入账</span>
            <span v-else>{{ formDateFormat(scope.row.billTime) }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="batchReceipt"
          label="票据号"
          width="100"
          align="center"
          sortable="custom"
        >
          <template slot-scope="scope">
            <div v-for="item in scope.row.batchReceipt">
              <span>{{ item.billNo }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="educationType" label="学历层次" width="150" align="center">
        </el-table-column>
        <el-table-column prop="shotSubject" label="采集对象" width="120" align="center">
          <template slot-scope="scope">
            <span v-show="scope.row.shotSubject == 0">新生</span>
            <span v-show="scope.row.shotSubject == 1">毕业生</span></template
          >
        </el-table-column>
        <el-table-column
          prop="graduationYear"
          label="毕业年份"
          width="160"
          align="center"
        >
        </el-table-column>

        <el-table-column prop="batchName" label="操作" align="center" width="260">
          <template slot-scope="scope">
            <el-button
              type="text"
              v-if="collectionButton"
              @click="handleCollection(scope.row)"
            >
              确认已收款
            </el-button>
            <el-button type="text" v-if="infoButton" @click="handleInfo(scope.row)">
              查看明细
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div style="display: flex; justify-content: right">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 20, 30, 40, 50, 100]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <!-- 查看明细弹窗 -->
    <el-dialog title="查看明细" :visible.sync="InfoVisibleview" width="80%" class="ERER">
      <!-- 搜索部分 -->
      <div style="display: flex; flex-wrap: wrap">
        <div class="condition-item">
          <label class="fn-14">拍照序号</label>
          <el-input
            style="width: 120px"
            placeholder="请输入拍照序号"
            autocomplete="off"
            size="small"
            v-model="infosearch.searchIndexNumber"
            clearable
          ></el-input>
        </div>
        <div class="condition-item">
          <label class="fn-14">姓名</label>
          <el-input
            style="width: 120px"
            placeholder="请输入姓名"
            autocomplete="off"
            size="small"
            clearable
            v-model="infosearch.searchContact"
          ></el-input>
        </div>
        <div class="condition-item">
          <label class="fn-14">身份证号</label>
          <el-input
            style="width: 180px"
            placeholder="请输入身份证号"
            autocomplete="off"
            size="small"
            clearable
            v-model="infosearch.searchIdNumber"
          ></el-input>
        </div>
        <div class="condition-item">
          <el-button
            type="primary"
            size="small"
            style="width: 85px"
            @click="handleSearchInfo"
            >查询</el-button
          >
        </div>
      </div>

      <!-- 弹窗列表盒子 -->
      <div>
        <el-table
          border
          :data="InfoList"
          style="width: 100%; text-align: center"
          height="400"
          :header-cell-style="{ background: '#f5f7fa', color: '#606266' }"
          v-loading="tableInfoLoading"
        >
          <template #empty>
            <p>
              {{ tableInfoLoading == true ? "数据加载中" : "暂无数据" }}
            </p>
          </template>

          <el-table-column prop="batchIndex" label="拍照序号" width="120" fixed>
          </el-table-column>
          <el-table-column align="center" prop="name" label="姓名" width="120">
            <template slot-scope="scope">
              <span @click="PhotoView(scope.row)" style="cursor: pointer">
                <i class="el-icon-picture"></i
                >{{ scope.row.name }}</span
              >
            </template>
          </el-table-column>
          <el-table-column align="center" prop="idNumber" label="身份证号" width="240">
            <template slot-scope="scope">
              <span>{{ execDecrypt(scope.row.idNumber, "Idcard") }}</span></template
            >
          </el-table-column>
          <el-table-column align="center" prop="studentCode" label="学号" width="160">
          </el-table-column>
          <el-table-column align="center" prop="schoolType" label="学校类型" width="160">
          </el-table-column>
          <el-table-column
            align="center"
            prop="educationType"
            label="学历类型"
            width="160"
          >
          </el-table-column>
          <el-table-column align="center" prop="schoolCode" label="学校代码" width="160">
          </el-table-column>
          <el-table-column align="center" prop="schoolName" label="学校" width="180">
          </el-table-column>
          <el-table-column align="center" prop="major" label="专业" width="180">
          </el-table-column>
          <el-table-column align="center" prop="cjm" label="采集码" width="180">
          </el-table-column>
        </el-table>
      </div>
      <div style="display: flex; justify-content: center">
        <el-pagination
          @size-change="handleSizeChangeInfo"
          @current-change="handleCurrentChangeInfo"
          :current-page="currentPageInfo"
          style="display: flex; justify-content: center; flex-direction: row"
          :page-sizes="[10, 20, 30, 40, 50, 100]"
          :page-size="pageSizeInfo"
          layout="total, sizes, prev, pager, next, jumper"
          :total="totalInfo"
        >
        </el-pagination>
      </div>
    </el-dialog>
    <!-- 查看照片 -->
    <el-dialog title="学生照片" :visible.sync="PhotoVisibleview" width="50%" class="ERER">
      <div style="text-align: center">
        <el-image :src="photoImg"></el-image>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { queryAllTask, confirmReceipt } from "@/api/ticket";
import { dateFormat } from "@/utils/date";
import { getToken } from "@/utils/auth";
import axios from "axios";
import { queryInfoByTask } from "@/api/batch";
import { formatPh, formatName, formatIdCard } from "@/utils/validate";
import { queryPageButton } from "@/api/permission";

export default {
  name: "Outstanding",
  data() {
    return {
      // 列表
      ticketList: [],
      ticketLoading: false,
      // 搜索条件
      taskName: "",
      searchTitle: "",
      searchCollectType: null,
      searchContact: "",
      searchPhotographer: "",
      searchBillNo: "",
      CollectTypeList: [
        {
          label: "校拍",
          value: "0",
        },
        {
          label: "散拍",
          value: "1",
        },
      ],

      loading: false,
      currentPage: 1,
      pageSize: 20,
      total: 0,

      // 下载
      DownloadLoading: false,
      // 查看明细
      InfoList: [],
      tableInfoLoading: false,
      InfoVisibleview: false,
      // 明细搜索
      infosearch: {
        searchContact: "",
        searchIdNumber: null,
        searchIndexNumber: null,
      },
      InfoBatchId: null,
      InfoCollectType: null,
      currentPageInfo: 1,
      pageSizeInfo: 20,
      totalInfo: 0,
      PhotoVisibleview: false,
      photoImg: null,
      // 排序
      isBatchTime: null,
      isCollectionTime: null,
      isBillNo: null,

      // 权限按钮
      searchButton: false,
      downloadButton: false,
      collectionButton: false,
      infoButton: false,
    };
  },
  created() {
    this.query();
    this.queryButton();
  },
  methods: {
    queryButton() {
      const data = 133;
      queryPageButton(data).then((resp) => {
        for (const button of resp.data) {
          if (button.code === "search") {
            this.searchButton = true;
          }
          if (button.code === "downloadButton") {
            this.downloadButton = true;
          }
          if (button.code === "collectionButton") {
            this.collectionButton = true;
          }
          if (button.code === "infoButton") {
            this.infoButton = true;
          }
        }
      });
    },
    query() {
      const data = {
        taskName: this.taskName,
        title: this.searchTitle,
        collectType: this.searchCollectType,
        photographer: this.searchPhotographer,
        billNo: this.searchBillNo,
        contact: this.searchContact,
        isCollection: 0,
        isBill: 0,
        isBatchTime: this.isBatchTime,
        isCollectionTime: this.isCollectionTime,
        isBillNo: this.isBillNo,
      };
      this.ticketLoading = true;
      queryAllTask(data, this.currentPage, this.pageSize).then((resp) => {
        this.ticketList = resp.data.content;
        this.total = resp.data.totalElements;
        this.ticketLoading = false;
      });
    },
    // 排序规则
    sortChange(custom) {
      if (custom.prop == "batchTime") {
        if (custom.order == "ascending") {
          this.isBatchTime = "1";
        } else if (custom.order == "descending") {
          this.isBatchTime = "2";
        }
      } else if (custom.prop == "collectionTime") {
        if (custom.order == "ascending") {
          this.isCollectionTime = "1";
        } else if (custom.order == "descending") {
          this.isCollectionTime = "2";
        }
      } else if (custom.prop == "batchReceipt") {
        if (custom.order == "ascending") {
          this.isBillNo = "1";
        } else if (custom.order == "descending") {
          this.isBillNo = "2";
        }
      }
      this.query();
    },
    // 确认已收款
    handleCollection(data) {
      this.$confirm("是否确认收款?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          const postData = { id: data.id, collectType: data.collectType };
          confirmReceipt(postData)
            .then((resp) => {
              if (resp.code == 0) {
                this.$notify({
                  title: "温馨提示",
                  message: resp.message,
                  type: "success",
                });
              } else {
                this.$notify({
                  title: "警告",
                  message: resp.data.msg,
                  type: "info",
                });
              }
              this.currentPage = 1;
              this.query();
            })
            .catch((error) => {});
        })
        .catch(() => {});
    },

    // 导出
    handleDownload() {
      this.DownloadLoading = true;
      axios({
        method: "GET",
        url: "/api/csias/sys/batchReceipt/export",
        params: {
          taskName: this.taskName,
          title: this.searchTitle,
          collectType: this.searchCollectType,
          photographer: this.searchPhotographer,
          billNo: this.searchBillNo,
          contact: this.searchContact,
          isCollection: 0,
          isBill: 0,
        },
        headers: {
          Authorization: "Bearer " + getToken(),
          "Content-Type": "application/json",
        },
        responseType: "blob",
        timeout: 1200000,
      }).then(
        (response) => {
          if (response.status === 200 && response.data) {
            let blob = new Blob([response.data], {
              type: "application/octet-stream",
            });

            let fileName = Date.parse(new Date()) + ".xlsx";
            if (window.navigator.msSaveOrOpenBlob) {
              navigator.msSaveBlob(blob, fileName);
            } else {
              var link = document.createElement("a");
              link.href = window.URL.createObjectURL(blob);
              link.download = fileName;
              link.click();
              //释放内存
              window.URL.revokeObjectURL(link.href);
            }
            this.$notify.success({
              title: "成功",
              message: "下载文件成功！",
            });
          } else {
            this.$notify.error({
              title: "错误",
              message: "下载失败，未查询到数据",
            });
          }
          this.DownloadLoading = false;
        },
        (err) => {
          this.DownloadLoading = false;
          this.$notify.error({
            title: "错误",
            message: "下载失败，未查询到数据",
          });
        }
      );
    },
    // 查看明细
    handleInfo(data) {
      this.InfoVisibleview = true;
      this.InfoVisibleview = true;
      this.InfoBatchId = data.id;
      this.InfoCollectType = data.collectType;
      this.InfoList = [];
      this.infosearch = {};
      let postData = {
        id: data.id,
        title: data.title,
        collectType: data.collectType,
      };
      this.InfobatchlList(postData);
    },
    InfobatchlList(data) {
      let postData = {
        id: data.id,
        title: data.title,
        collectType: data.collectType,
        batchIndex: this.infosearch.searchIndexNumber,
        name: this.infosearch.searchContact,
        idNumber: this.infosearch.searchIdNumber,
      };
      this.tableInfoLoading = true;
      queryInfoByTask(postData, this.currentPageInfo, this.pageSizeInfo).then((resp) => {
        if (resp.code == 0) {
          let dataview = resp.data.content;
          this.InfoList = dataview;
          this.totalInfo = resp.data.totalElements;
        }
        this.tableInfoLoading = false;
      });
    },
    // 查看照片
    PhotoView(data) {
      // pathCollectedPhoto
      const url =
        //window._config["baseUrl"] +
        "/api/csias/file/download/" +
        data.pathCollectedPhoto +
        "?timestamp=" +
        new Date().getTime();
      axios({
        method: "GET",
        url: url,
        headers: {
          Authorization: "Bearer " + getToken(),
        },
        responseType: "blob",
        timeout: 180000,
      }).then(
        (response) => {
          if (response.status === 200 && response.data) {
            this.PhotoVisibleview = true;
            const imgContent = response.data;
            this.photoImg = window.URL.createObjectURL(imgContent);
          }
        },
        (err) => {
          this.$notify.error({
            title: "错误",
            message: "照片加载失败，未查询到照片信息",
          });
        }
      );
    },
    handleSearch() {
      this.currentPage = 1;
      this.query();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.query();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.query();
    },
    // 查看明细列表分页
    handleSearchInfo() {
      let postData = {
        id: this.InfoBatchId,
        collectType: this.InfoCollectType,
      };
      this.currentPageInfo = 1;
      this.InfobatchlList(postData);
    },
    handleSizeChangeInfo(val) {
      let postData = {
        id: this.InfoBatchId,
        collectType: this.InfoCollectType,
      };
      this.pageSizeInfo = val;
      this.InfobatchlList(postData);
    },
    handleCurrentChangeInfo(val) {
      let postData = {
        id: this.InfoBatchId,
        collectType: this.InfoCollectType,
      };
      this.currentPageInfo = val;
      this.InfobatchlList(postData);
    },
    columnDateFormat(row, column, cellValue, index) {
      const dateValue = row[column.property];
      if (dateValue != null) {
        return dateFormat("YYYY-mm-dd", new Date(dateValue));
      }
    },
    formDateFormat(dateValue) {
      if (dateValue != null) {
        return dateFormat("YYYY-mm-dd", new Date(dateValue));
      }
    },
    // 姓名、手机号脱敏
    execDecrypt(str, mod) {
      if (!str) {
        return str;
      } else if (mod == "ph") {
        return formatPh(str);
      } else if (mod == "name") {
        return formatName(str);
      } else if (mod == "Idcard") {
        return formatIdCard(str);
      }
    },
  },
};
</script>

<style></style>
